import React from 'react'

import Layout from 'components/Layout'

const Naprapati = ({ data }) => (
  <Layout pageTitle='Om Naprapati'>
    <oma-grid-row>
      <div className='section'>
        <h1 className='heading page__heading'>Om naprapati</h1>
        <h2 className='heading section__heading'>
          Naprapatisk manuell terapi – minskar smärta och återskapar funktion
        </h2>
        <p className='section__text'>
          Naprapaten undersöker, diagnostiserar, behandlar och rehabiliterar
          smärttillstånd och nedsatt funktion i hela det neuromuskuloskeletala
          systemet. I behandlingen tas hänsyn till såväl leder och skelett som
          muskler och övriga mjukdelar och just denna helhetssyn där specifik
          och hög kompetens inom naprapatisk manuell terapi kombineras med goda
          kunskaper inom ortopedisk och annan konventionell medicin är själva
          kärnan inom naprapati. En naprapat arbetar främst med sina händer och
          kombinerar på ett effektivt sätt olika manuella behandlingstekniker.
          Oftast kombineras ledspecifika behandlingstekniker som manipulation
          och mobilisering med mjukdelsbehandling som massage, bindvävstekniker
          och stretching.
        </p>
        <p className='section__text'>
          De manuella behandlingsteknikerna kombineras vid behov med till
          exempel elektroterapi som TENS och stötvågsbehandling. Många
          naprapater använder även akupunktur som ett komplement. För att nå ett
          varaktigt resultat är naprapaten noga med att motivera patienten att
          själv vara delaktig i rehabiliteringen och behandlingen följs vanligen
          upp med individuella träningsprogram och råd om arbetsställningar.
        </p>
        <p className='section__text'>
          Naprapatens breda kompetens inom rörelse- och stödjeorgan gör att
          arbetsområdet för en naprapat också är brett. De flesta naprapater
          driver egna kliniker men många är också anställda eller har uppdrag på
          företag eller inom idrottsrörelsen i föreningar och förbund. Vissa
          naprapater har avtal med landsting även om antalet vårdavtal ännu inte
          är så många.
        </p>
        <h2 className='heading section__heading'>
          Vilka besvär behandlar en naprapat?
        </h2>
        <p className='section__text'>
          En naprapat undersöker, dignostiserar, behandlar och rehabiliterar
          smärttillstånd och nedsatt funktion i rörelse- och stödjeorganen. När
          samspelet mellan muskler, leder och nervsystem inte fungerar optimalt
          uppstår ofta smärta som en följd av den nedsatta funktionen. Utöver
          att behandla nedsatt funktion och smärta i ryggradens alla delar
          behandlar en naprapat även besvär från alla kroppens övriga leder samt
          specifika smärttillstånd och diagnoser som till exempel
          spänningshuvudvärk, musarm, tennisarmbåge, olika muskelbristningar,
          ishias, löpar- och hopparknä och benhinneproblematik.
        </p>
        <p className='section__text'>
          Naprapatens professionella omhändertagande, baserat på helhetssyn och
          en brett upplagd undersökning med efterföljande specifik och adekvat
          behandling, ger en effektiv smärtlindring för patienten. Utöver
          vinsten för patienten möjliggörs genom naprapatbehandlingen även en
          snabbare återgång till arbetslivet efter sjukskrivning, vilket i sin
          tur gör naprapati intressant även ur ett samhällsekonomiskt
          perspektiv. Först ta bort smärtan. Sedan se till att den inte kommer
          tillbaka.
        </p>

        <p className='section__text'>
          Behandlingen syftar primärt till att minska smärtan och återvinna
          kroppens naturliga funktion. Därefter läggs vikt vid att analysera och
          åtgärda bakomliggande orsaker som till exempel muskulär obalans eller
          brister i arbetsmiljön. Den analysen leder vanligen till individuellt
          anpassade träningsprogram, råd om arbetsställningar och i vissa fall
          även specifika arbetsplatsanalyser.
        </p>
        <p className='section__text'>
          I rehabiliteringen involveras patienten själv på ett tydligt sätt
          vilket är en förutsättning för långsiktiga resultat. Sammantaget gör
          detta naprapatin till ett mycket effektivt behandlingssystem där man
          noggrant utreder patientens besvär och därefter behandlar i syfte att
          minska smärtan och återställa funktionen för att slutligen se till att
          tillsammans med patienten förebygga framtida besvär.
        </p>
        <h2 className='heading section__heading'>
          Legitimation och skyddad titel
        </h2>
        <p className='section__text'>
          Sedan 1994 är naprapat ett legitimationsyrke. Efter godkänd 4-årig
          heltidsutbildning och Naprapatexamen samt därutöver ett års
          praktiktjänstgöring som naprapat och inom offentlig sjukvård kan
          ansökan om legitimation göras hos Socialstyrelsen. 2007 fattades
          beslut om att göra naprapat till en skyddad yrkestitel vilket innebär
          att man måste vara under praktiktjänstgöring eller legitimerad
          naprapat för att få använda titeln. En legitimerad naprapat arbetar
          enligt samma lagar och förordningar som övrig hälso- och
          sjukvårdspersonal. Vården ska ges i överensstämmelse med vetenskap och
          beprövad erfarenhet. Legitimerade naprapater har sådana kunskaper att
          de kan bedöma vilka patienter som ska erhålla andra typer av
          undersökningar och vård. Detta gör att naprapaten kan arbeta under
          självständigt medicinskt yrkesansvar och ha ett primärt diagnosansvar.
        </p>
        <h2 className='heading section__heading'>
          Legitimerade naprapater kvalitetssäkrar arbetet
        </h2>
        <p className='section__text'>
          Det åligger varje legitimerad naprapat att bedriva
          kvalitetssäkringsarbete i enlighet med Socialstyrelsens föreskrifter.
          Svenska Naprapatförbundet har utvecklat ett kvalitetssäkringssystem
          som är utformat för att fungera i naprapatens dagliga arbete och
          bygger på fyra enkäter: Kvalitet på mottagningen, Resultatuppföljning
          naprapatvård, Klinikuppföljning samt Avvikelserapport. Varje år skall
          också en Patientsäkerhetsberättelse upprättas av naprapaten, precis
          som av alla andra grupper inom hälso- och sjukvård. I den beskrivs hur
          man bedrivit och utvecklat sitt arbete med patientsäkerhet under året.
        </p>
        <h2 className='heading section__heading'>
          Konsten att korrigera orsaken till lidande
        </h2>
        <p className='section__text'>
          Naprapatin grundades i början av 1900-talet av dr. Oakley Smith. Han
          arbetade från början tillsammans med dåtidens kiropraktorer men
          övergav tidigt tanken att “kotor kan hoppa ur led”, den s k
          subluxationsteorin, som var den teori som då var förhärskande inom
          kiropraktorleden. Smith menade istället att orsaken till smärta och
          besvär i rörelseapparaten (muskler, skelett, leder och nervsystem),
          snarare kunde härledas till bindvävsförändringar och
          spänningstillstånd i omgivande mjukdelar, det han kallade för
          ¨connective tissue¨, än till rent mekaniska störningar (subluxationer)
          i ryggraden och andra skelettdelar.
        </p>

        <p className='section__text'>
          Smith grundade sin uppfattning på forskningsfynd av
          vävnadsförändringar, som han ansåg skulle kunna ge upphov till olika
          typer av smärttillstånd. Som en direkt följd därav insåg han att dessa
          besvär inte kunde behandlas enbart genom ledmanipulation, utan att det
          även krävdes behandling av omkringliggande mjukdelar för att nå ett
          bra och bestående behandlingsresultat.
        </p>
        <h2 className='heading section__heading'>
          Oakley Smith och naprapatins historia
        </h2>
        <p className='section__text'>
          Oakley Smith (1880–1967) tog 1899 examen som kiropraktor vid Palmer
          School of Chiropractic i Iowa. Samma år antogs han som elev vid
          Medical School, University of Iowa. Efter tre år avbröt han
          medicinstudierna för att istället arbeta tillsammans med Daniel D.
          Palmer, kiropraktikens grundare, vid Palmer School of Chiropractic.
        </p>
        <p className='section__text'>
          Ett par år senare flyttade Smith till Chicago där han öppnade en egen
          kiropraktorklinik. Samtidigt bröt han med Palmer och dennes teori om
          mekaniska störningar i ryggraden som allenarådande sjukdomsorsak.
          Oakley Smith hade en annan teori och skulle snart få stöd för sina
          tankar.
        </p>
        <p className='section__text'>
          Under en studieresa i Europa kom han i kontakt med de manuella
          behandlingar som fanns inom den folkliga traditionen i Böhmen (dagens
          Tjeckien). Smith blev fascinerad och tillbaka i USA sökte han upp
          böhmska utvandrare för att lära sig mer om dessa manuella
          behandlingsformer. De lärdomar han drog skulle få en avgörande
          betydelse för naprapatins utveckling. 1906 gav Smith tillsammans med
          ett par andra författare ut boken “A Textbook of Modernized
          Chiropractic”. I boken beskrevs det som Smith senare skulle benämna
          naprapati*.
        </p>
        <p className='section__text'>
          1907 startade Smith den första utbildningen inom naprapatin, Oakley
          Smith School of Naprapathy i Chicago. Därmed lades grunden för
          naprapatin, både som företeelse och behandlingsform. Detta har vi
          uppmärksammat genom att under 2007 fira naprapatins 100-årsjubileum.
        </p>
        <p className='section__text'>
          1949 startades ytterligare en skola i Chicago, National College of
          Naprapathy. När de båda skolorna slogs samman 1971 ändrades namnet
          till Chicago National College of Naprapathy (idag: National College of
          Naprapathic Medicine).
        </p>
        <p className='section__text section__text--italic'>
          * Ordet naprapati har sitt ursprung i det tjeckiska ordet ”napravit”
          som betyder korrigera och det grekiska ordet ”pathos” som betyder
          lidande. Som helhet ger detta naprapatin innebörden ”korrigera orsak
          till lidande”.
        </p>
        <p className='section__text'>
          Källa:{' '}
          <a className='link' href='https://www.naprapater.se'>
            Svenska Naprapatförbundet
          </a>
        </p>
      </div>
    </oma-grid-row>
  </Layout>
)

export default Naprapati
